import React from "react";
import { Box, Typography, Paper, Accordion } from "@mui/material";
import * as commFuncs from "../../funcs/";
import * as memFuncs from "../../funcs/membership";

const Roster = ({ onLoaded }) => {
  const { users } = commFuncs.useUsers();

  const rankOrder = [
    "Commander",
    "Captain",
    "First Lieutenant",
    "Corporal",
    "Private",
    "Recruit",
  ];
  const sortedUsers = memFuncs.sortUsersByRank(users);

  return (
    <Paper
      elevation={0}
      sx={{
        padding: "20px",
        height: "fit-content",
        backgroundColor: "rgba(0,20,0,0.25)",
        boxShadow: "0 0 7px green",
        borderRadius: "5px",
      }}
    >
      {rankOrder.map((rank) => (
        <Box key={rank}>
          <Typography
            variant="subtitle1"
            color="white"
            sx={{
              marginTop: "16px",
              fontWeight: "bold",
              textDecoration: "underline",
            }}
          >
            {rank.toUpperCase()}
          </Typography>
          {sortedUsers
            .filter((user) => user.rank === rank)
            .map((user) => (
              <Accordion
                key={user.id}
                sx={{ marginBottom: "8px", bgcolor: "rgba(0,0,0,0)" }}
                elevation={0}
              >
                <Typography color="white" sx={{ textAlign: "center" }}>
                  {user.username}
                </Typography>
              </Accordion>
            ))}
        </Box>
      ))}
    </Paper>
  );
};

export default Roster;

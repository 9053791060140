import React from "react";
import { Box, Typography } from "@mui/material";

const RoleCardHeader = ({ group, sides }) => {
  return (
    <Box
      sx={{
        backgroundColor: sides(group.Roles[0].side)[0],
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        textAlign: "center",
        height: "65px",
      }}
    >
      <Typography variant="h6" color="white" sx={{ fontWeight: "bold" }}>
        {group.GroupName.toUpperCase()}
      </Typography>

      <Typography variant="body" color="white" sx={{ fontWeight: "bold" }}>
        {sides(group.Roles[0].side, 1)}
      </Typography>
    </Box>
  );
};

export default RoleCardHeader;

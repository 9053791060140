import { useState, useEffect } from "react";
import getUsers from "../funcs/getUsers";

export const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const userList = await getUsers();
        setUsers(userList);
      } catch (err) {
        setError(err);
      }
    };

    fetchUsers();
  }, []);

  return { users, error };
};

import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Input,
} from "@mui/material";
import { apiRequest } from "../../../funcs/events";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

const RoleCardRoles = ({ role, auth, style }) => {
  return (
    <>
      <ListItemIcon>
        <Avatar
          sx={{
            backgroundColor: style[0],
          }}
        >
          <MilitaryTechIcon />
        </Avatar>
      </ListItemIcon>
      <ListItemText
        style={{ width: "70%" }}
        primary={"Role"}
        primaryTypographyProps={{
          color: "white",
          fontWeight: "bold",
          textAlign: "left",
        }}
        secondaryTypographyProps={{
          color: "goldenrod",
          fontWeight: "bold",
          textAlign: "left",
        }}
        secondary={role.RoleName}
      />
      <ListItemText
        style={
          auth
            ? {
                width: "0%",
                borderLeft: "2px solid rgba(218, 165, 32, 0.4)",
              }
            : {
                width: "100%",
                marginRight: "-81%",
                borderLeft: "2px solid rgba(218, 165, 32, 0.4)",
              }
        }
      />
      <ListItemText
        style={{
          width: "75%",
          borderLeft: "2px solid rgba(218, 165, 32, 0.4)",
        }}
        primary={"Assigned Player"}
        primaryTypographyProps={{
          color: "white",
          fontWeight: "bold",
          textAlign: "right",
        }}
        secondary={role.assigned_to ? role.assigned_to : "None"}
        secondaryTypographyProps={{
          color: "goldenrod",
          fontWeight: "bold",
          textAlign: "right",
        }}
      />
    </>
  );
};

export default RoleCardRoles;

/*
Example usage:

import useCurrentUser from './hooks/useCurrentUser';
const { currentUser, loading } = useCurrentUser();

React.useEffect(() => {
    if (!loading && (!currentUser || !currentUser.admin)) {
        navigate('/');
    }
}, [loading, currentUser, navigate]);


we need to check if currentUser is set 1st, then check the data secondly 
otherwise we will throw errors:
currentUser && currentUser.admin ? () : ()

When implementing this, we also need to hide the page until loading is false, 
otherwise currentUser will be empty
*/

import { useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../funcs/firebase/firebase-config"; // <- Use 'db' instead of 'firestore'

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userDocRef = doc(db, "users", user.uid); // <- Use 'db' here
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          setCurrentUser({ uid: user.uid, ...userDoc.data() });
        }
      } else {
        setCurrentUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return { currentUser, loading };
};

export default useCurrentUser;

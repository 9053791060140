import { useState, useEffect } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../funcs/firebase/firebase-config";

const useFetchUsers = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersResult = [];

        querySnapshot.forEach((doc) => {
          usersResult.push({ ...doc.data(), uid: doc.id });
        });

        setUsers(usersResult);
      } catch (error) {
        console.error("Failed to fetch users:", error);
      }
    };

    fetchUsers();
  }, []);

  return { users };
};

export default useFetchUsers;

import React, { useState } from "react";
import RoleSelector from "./roles/RoleSelector";
// import { useSelector } from "react-redux";
import Modal from "../Modal";
import "./eventStyles.css";
import "../../styles.css";
import {
  Card,
  Box,
  Stack,
  Typography,
  IconButton,
  Tooltip,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircle } from "@fortawesome/free-solid-svg-icons";
import * as eventFuncs from "../../funcs/events";
import EventModalHeader from "./modal/EventModalHeader";
import AddGroup from "./roles/edit/AddGroup";

function EventItem({ event, currentUser }) {
  const [showEditEventModal, setShowEdit] = useState(false);
  const [showRoles, setShowRoles] = useState(false);
  //const userInfo = useSelector((state) => state.auth.user);
  const combinedRoles = event.Groups.flatMap((group) =>
    group.Roles.map((role) => ({ ...role, GroupName: group.GroupName }))
  );
  // const hasAssignedRole = combinedRoles.some(
  //   (role) => role.assigned_to === userInfo?.displayName
  // );
  // const [showFullDescription, setShowFullDescription] = useState(false);

  const isLongDescription = (text) => {
    return text.length > 190;
  };

  const deleteEvent = async (eventId) => {
    const body = {
      event_id: eventId,
    };
    await eventFuncs.apiRequest("events", "DELETE", body);
  };

  const handleEditPublished = async (eventId, published) => {
    const data = {
      event_id: eventId,
      published: published,
    };

    await eventFuncs.apiRequest("event/published", "POST", data);
  };

  return (
    <>
      {(showEditEventModal || showRoles) && (
        <div
          className={`blur-background2 ${
            showEditEventModal || showRoles ? "show" : ""
          }`}
        ></div>
      )}
      <Card
        variant="outlined"
        sx={{
          width: "100%",
          maxWidth: 350,
          height: "100%",
          maxHeight: 300,
          bgcolor: "#1d1d1d",
          boxShadow: "0 0 7px green",
          padding: "20px",
          backgroundColor: "rgba(0, 20, 0, 0.25)",
        }}
      >
        <Box
          sx={{
            backgroundColor: "#e6e6e6",
            border: "#adadad",
            borderRadius: "3px",
            margin: "-20px",
            width: "60%",
            paddingLeft: "10px",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontFamily: "Quantico", color: "#333" }}
          >
            {event.published === "0" && "Unpublished"}
            MISSION DATE: {event.date}
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            top: 0,
            right: 0,
            marginLeft: "300px",
          }}
        >
          {currentUser && currentUser.missionMaker ? (
            <>
              <IconButton className="icon" disableFocusRipple={true}>
                <Tooltip title={<Typography>Delete Event</Typography>}>
                  <FontAwesomeIcon
                    className="delete-event"
                    icon={faTrash}
                    size="lg"
                    color="red"
                    onClick={() => deleteEvent(event.event_id)}
                  />
                </Tooltip>
                {event.published ? (
                  <Tooltip title={<Typography>Unpublish Event</Typography>}>
                    <FontAwesomeIcon
                      className="unpublish-event"
                      onClick={() => handleEditPublished(event.event_id, 0)}
                      icon={faCircle}
                      size="lg"
                      color="green"
                    />
                  </Tooltip>
                ) : (
                  <Tooltip title={<Typography>Publish Event</Typography>}>
                    <FontAwesomeIcon
                      className="publish-event"
                      onClick={() => handleEditPublished(event.event_id, 1)}
                      icon={faCircle}
                      size="lg"
                      color="red"
                    />
                  </Tooltip>
                )}
              </IconButton>
            </>
          ) : (
            <div className="icon-placeholder">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </Box>
        <Box
          sx={{
            p: 2,
            borderBottom: "1px solid green",
            minHeight: "200px",
            marginTop: "-30px",
            maxHeight: "200px",
          }}
        >
          <div className="role-card-content">
            <Stack>
              <Typography
                color="white"
                sx={{ fontFamily: "Quantico", textAlign: "center" }}
                gutterBottom
                variant="h4"
                component="div"
              >
                {event.name.toUpperCase()}
              </Typography>
            </Stack>
            {isLongDescription(event.description) ? (
              <>
                <Typography
                  color="white"
                  sx={{ fontFamily: "Quantico", whiteSpace: "pre-line" }}
                  align="center"
                >
                  {event.description.slice(0, 223)}...
                </Typography>
                {/* <div className="new-group">
                  <FormButton
                    type="submit"
                    text="Read More"
                    onClick={() => setShowFullDescription(true)}
                    hidden={
                      !userInfo?.displayName ||
                      !userInfo?.emailVerified ||
                      hasAssignedRole
                    }
                  />
                </div> */}
              </>
            ) : (
              <Typography
                color="white"
                sx={{ fontFamily: "Quantico", whiteSpace: "pre-line" }}
              >
                {event.description}
              </Typography>
            )}
          </div>
        </Box>
        <Box sx={{ minHeight: 150, paddingTop: "10px", maxHeight: "150px" }}>
          <div className="role-card-buttons">
            <button
              className="role-button-style"
              onClick={() => setShowRoles(!showRoles)}
              hidden={
                !combinedRoles ||
                combinedRoles.length === 0 ||
                combinedRoles[0].RoleName === null
              }
            >
              Show Roles
            </button>
            {currentUser && currentUser.missionMaker && (
              <button
                className="role-button-style"
                onClick={() => setShowEdit(true)}
              >
                Edit
              </button>
            )}
          </div>
        </Box>
        <Modal isOpen={showRoles} onClose={() => setShowRoles(false)}>
          <EventModalHeader event={event} setShowRoles={setShowRoles}>
            <RoleSelector event={event} currentUser={currentUser} />
          </EventModalHeader>
        </Modal>

        {/* <Modal
          isOpen={showFullDescription}
          onClose={() => setShowFullDescription(false)}
          readme={1}
        >
          <Button onClick={() => setShowFullDescription(false)}> Close</Button>
          <Typography
            color="white"
            sx={{ whiteSpace: "pre-line" }}
            align="center"
          >
            {event.description}
          </Typography>
        </Modal> */}

        <Modal isOpen={showEditEventModal} onClose={() => setShowEdit(false)}>
          <EventModalHeader
            event={event}
            editMode={1}
            setShowRoles={setShowEdit}
          >
            <AddGroup
              eventId={event.event_id}
              description={event.description}
            />
            <RoleSelector
              event={event}
              currentUser={currentUser}
              editMode={1}
            />
          </EventModalHeader>
        </Modal>
      </Card>
    </>
  );
}

export default EventItem;

export const apiRequest = async (endpoint, method = "GET", payload = null) => {
  try {
    const options = {
      method,
      headers: {
        "Content-Type": "application/json",
      },
    };

    if (
      payload &&
      (method === "POST" ||
        method === "PUT" ||
        method === "PATCH" ||
        method === "DELETE")
    ) {
      options.body = JSON.stringify(payload);
    }

    const response = await fetch(
      `https://ap2.chrono-company.com/api/v2/${endpoint}`,
      options
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      const data = await response.json();

      if (data.status !== "success") {
        throw new Error(data.message || "Something went wrong");
      }

      return data;
    } else {
      throw new Error("Response was not in JSON format");
    }
  } catch (error) {
    console.error("Failed to fetch data:", error);
    throw error;
  }
};

import React, { useState } from "react";
import { Box, Typography, IconButton, Paper, Input } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { apiRequest } from "../../../funcs/events";

const EventModalHeader = ({ event, children, setShowRoles, editMode }) => {
  const [newEventName, setNewEventName] = useState(event.name);
  const [newEventDate, setNewEventDate] = useState(event.date);
  const [dateError, setDateError] = useState(false);
  const [changed, setChanged] = useState(false);

  const handleEventNameChange = (e) => {
    const newName = e.target.value;
    setNewEventName(newName);
    setChanged(true);
  };

  const handleBlur = (e) => {
    if (!changed) {
      return;
    }
    setChanged(false);
    const newEventName = e.target.value;
    const data = {
      event_id: event.event_id,
      name: newEventName,
    };
    apiRequest("event/name", "POST", data);
  };

  const isValidDate = (dateString) => {
    var regEx = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateString.match(regEx)) return false;
    var d = new Date(dateString);
    var dNum = d.getTime();
    if (!dNum && dNum !== 0) return false;
    return d.toISOString().slice(0, 10) === dateString;
  };
  const handleEventDateChange = (e) => {
    const newDate = e.target.value;
    if (!isValidDate(newDate)) {
      setDateError(true);
      setNewEventDate(newDate);
      return;
    }
    setDateError(false);
    setNewEventDate(newDate);
    const data = {
      event_id: event.event_id,
      date: newDate,
    };
    apiRequest("event/date", "POST", data);
  };

  return (
    <Paper
      elevation={3}
      variant="outlined"
      sx={{
        borderRadius: "15px",
        border: "2px solid rgb(47,47,47)",
        overflow: "hidden",
        backgroundColor: "rgba(26,26,26,1)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "rgb(47,47,47)",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
          textAlign: "center",
          height: "65px",
        }}
      >
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
          }}
        >
          <CancelIcon
            onClick={() => setShowRoles(false)}
            sx={{ scale: "1.5" }}
          />
        </IconButton>
        {editMode ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Input
              placeholder={event.name}
              onChange={handleEventNameChange}
              value={newEventName}
              onBlur={handleBlur}
              sx={{
                color: "white",
              }}
            />
            <Input
              placeholder={event.date}
              onChange={handleEventDateChange}
              value={newEventDate}
              sx={{
                color: dateError ? "red" : "white",
              }}
            />
          </div>
        ) : (
          <>
            <Typography variant="h5" color="white" sx={{ fontWeight: "bold" }}>
              {event.name}
            </Typography>{" "}
            <Typography variant="h6" color="white" sx={{ fontWeight: "bold" }}>
              {event.date}
            </Typography>
          </>
        )}
      </Box>
      <div
        style={{
          maxHeight: "100vh",
          overflow: "auto",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {children}
      </div>
    </Paper>
  );
};

export default EventModalHeader;

import React, { useEffect, useState } from "react";
import Navbar from "./components/assets/NavBar";
import HomePage from "./components/HomePage";
import AboutUs from "./components/AboutUs";
import Events from "./components/Events";
import HeadQuarters from "./components/HeadQuarters";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { setUser, logout } from "./store/actions/authActions";
import { auth } from "./components/funcs/firebase/firebase-config";
import { useDispatch } from "react-redux";
import UserManagement from "./components/UserManagement";
import LandingPage from "./components/LandingPage";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const THEME = createTheme({
  typography: {
    fontFamily: "'Quantico', sans-serif",
    fontSize: 12,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    color: "white",
  },
  components: {
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: "white",
        },
        title: {
          color: "white",
        },
        subheader: {
          color: "white",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          textAlign: "center",
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          textAlign: "center",
        },
      },
    },
  },
});

export { ThemeProvider, THEME };

const App = () => {
  const [showLandingPage, setShowLandingPage] = useState(true);
  const dispatch = useDispatch();
  const [checkingAuth, setCheckingAuth] = useState(true);

  useEffect(() => {
    const hasVisited = localStorage.getItem("hasVisited");
    if (hasVisited) {
      setShowLandingPage(false);
    }
  }, []);

  const handleDismiss = () => {
    localStorage.setItem("hasVisited", "true");
    setShowLandingPage(false);
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        dispatch(
          setUser({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            emailVerified: user.emailVerified,
          })
        );
      } else {
        dispatch(logout());
      }
      setCheckingAuth(false);
    });

    return () => unsubscribe();
  }, [dispatch]);

  if (checkingAuth) {
    return null;
  }

  return (
    <>
      <ThemeProvider theme={THEME}>
        {showLandingPage ? (
          <LandingPage onDismiss={handleDismiss} />
        ) : (
          <Router>
            <Navbar />
            <Routes>
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/missions" element={<Events />} />
              <Route path="/hq" element={<HeadQuarters />} />
              <Route path="/" element={<HomePage />} />
              <Route path="/ManageUsers" element={<UserManagement />} />
            </Routes>
          </Router>
        )}
      </ThemeProvider>
    </>
  );
};

export default App;

import React, { useState } from "react";
import { Box, TextareaAutosize, Avatar, Input } from "@mui/material";
import { apiRequest } from "../../../../funcs/events";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SaveIcon from "@mui/icons-material/Save";

const AddGroup = ({ eventId, description }) => {
  const [newGroupName, setNewGroupName] = useState();
  const [newEventDescription, setNewEventDescription] = useState(description);

  const handleEventDescriptionChange = (e) => {
    const newDescription = e.target.value;
    setNewEventDescription(newDescription);
  };

  const handleSubmit = () => {
    const data = {
      event_id: eventId,
      description: newEventDescription,
    };
    apiRequest("event/description", "POST", data);
  };

  const handleNewGroupNameChange = (e) => {
    const newName = e.target.value;
    setNewGroupName(newName);
  };

  const submitAddGroup = (newGroup) => {
    if (newGroup === null) {
      return;
    }
    const data = {
      event_id: eventId,
      groupName: newGroup,
    };
    apiRequest("event/addgroup", "POST", data);
    setNewGroupName("");
  };
  return (
    <Box
      sx={{
        padding: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        margin: "auto",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "60%",
          backgroundColor: "rgba(0,0,0,0)",
          color: "white",
          justifyContent: "center",
        }}
      >
        <TextareaAutosize
          value={newEventDescription}
          onChange={handleEventDescriptionChange}
          minRows={1}
          maxRows={10}
          style={{
            width: "100%",
            backgroundColor: "rgba(0,0,0,0)",
            color: "white",
            border: "2px solid rgba(218, 165, 32, 0.4)",
            marginRight: 10,
          }}
        />
        <Avatar onClick={handleSubmit}>
          <SaveIcon />
        </Avatar>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "60%",
          backgroundColor: "rgba(0,0,0,0)",
          color: "white",
          justifyContent: "center",
          marginTop: 10,
        }}
      >
        <Input
          placeholder="New Group Name"
          sx={{ color: "white", marginTop: 3, marginBottom: 1 }}
          onChange={handleNewGroupNameChange}
          value={newGroupName}
        ></Input>
        <Avatar
          sx={{ marginTop: 1 }}
          onClick={() => submitAddGroup(newGroupName)}
        >
          <AddCircleIcon />
        </Avatar>
      </div>
    </Box>
  );
};

export default AddGroup;

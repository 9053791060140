import React from "react";
import { Button } from "@mui/material";

const FormButton = ({
  text,
  onClick,
  hidden,
  href = "",
  disabled,
  p = 10,
  m = 0,
}) => {
  return (
    <Button
      onClick={onClick}
      hidden={hidden}
      href={href}
      disabled={disabled}
      sx={{
        padding: p + "px",
        border: "1px solid green",
        marginLeft: m + "px",
        backgroundColor: "transparent",
        color: "#fff",
        borderRadius: "15px",
        cursor: "pointer",
        "&:hover": {
          border: "1px solid #007bff",
          backgroundColor: "transparent",
          color: "#fff",
        },
      }}
    >
      {text}
    </Button>
  );
};

export default FormButton;

import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../components/funcs/firebase/firebase-config";

const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const AUTH_ERROR = "AUTH_ERROR";
const SET_ADMIN_STATUS = "SET_ADMIN_STATUS";

export const setUser = (user) => {
  if (user) {
    return {
      type: LOGIN_SUCCESS,
      payload: {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        emailVerified: user.emailVerified,
      },
    };
  } else {
    return {
      type: LOGOUT_SUCCESS,
      payload: null,
    };
  }
};

export const setAdminStatus = (isAdmin) => ({
  type: SET_ADMIN_STATUS,
  payload: isAdmin,
});

export const fetchAdminStatus = () => async (dispatch) => {
  const user = auth.currentUser;
  if (user) {
    user
      .getIdTokenResult()
      .then((idTokenResult) => {
        dispatch(setAdminStatus(!!idTokenResult.claims.admin));
      })
      .catch((error) => {
        console.error(error);
      });
  }
};

export const login = (email, password) => async (dispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    dispatch(setUser(userCredential.user));
    dispatch(fetchAdminStatus());
  } catch (error) {
    dispatch({ type: AUTH_ERROR, payload: error.message });
  }
};

export const logout = () => async (dispatch) => {
  try {
    await signOut(auth);
    dispatch({ type: LOGOUT_SUCCESS });
    dispatch(setUser(null));
    dispatch(setAdminStatus(false));
  } catch (error) {
    dispatch({ type: AUTH_ERROR, payload: error.message });
  }
};

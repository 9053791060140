import React, { useEffect } from "react";
import FormButton from "./assets/buttons/FormButton";
import { Typography, Card, CardContent, Box } from "@mui/material";
import "./styles.css";

const LandingPage = ({ onDismiss }) => {
  useEffect(() => {
    document.title = "Chrono-Company: Landing Page";
  }, []);

  return (
    <>
      <meta charSet="utf-8" />
      <title>Landing Page</title>
      <div className="blur-background"></div>

      <div className="center-grid">
        <Box sx={{ position: "relative", width: "fit-content" }}>
          <div className="tv-static"></div>
          <Card
            sx={{
              textAlign: "center",
              bgcolor: "#1d1d1d",
              backgroundColor: "rgba(0, 20, 0, 0.25)",
              padding: "20px",
              position: "relative",
              zIndex: 1,
            }}
          >
            <CardContent>
              <Typography
                variant="h3"
                sx={{ fontFamily: "Quantico", color: "white" }}
              >
                WELCOME
              </Typography>
              <Typography
                variant="h4"
                sx={{ fontFamily: "Quantico", color: "white" }}
              >
                TO
              </Typography>
              <Typography
                variant="h3"
                sx={{ fontFamily: "Quantico", color: "white" }}
              >
                CHRONO COMPANY
              </Typography>
            </CardContent>
            <FormButton onClick={onDismiss} text="Enter" />
          </Card>
        </Box>
      </div>
    </>
  );
};

export default LandingPage;

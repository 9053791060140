import React from "react";
import { Grid, Paper, List, ListItem, Avatar } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import * as eventFuncs from "../../../funcs/events";
import EditCardHeader from "./edit/EditCardHeader";
import EditCardRoles from "./edit/EditCardRoles";
import RoleCardHeader from "./RoleCardHeader";
import AddRole from "./edit/AddRole";
import RoleCardRoles from "./RoleCardRoles";

const RoleCard = ({ groups, eventId, currentUser, auth, editMode }) => {
  const sides = (side, text) => {
    if (!text) {
      switch (side) {
        case "blu":
          return ["#333e4e", "#627795"];
        case "op":
          return ["#4e333e", "#956277"];
        case "ind":
          return ["#3e4e33", "#779562"];
        default:
          return "";
      }
    }
    switch (side) {
      case "blu":
        return "Blu-For";
      case "op":
        return "Op-For";
      case "ind":
        return "Independant";
      default:
        return "No Side";
    }
  };

  const assignRole = async (roleName, role_id, remove = 0) => {
    const data = {
      eventId: eventId,
      roleName: roleName,
      username: currentUser.username,
      role_id: role_id,
      remove: remove,
    };

    eventFuncs.apiRequest("event/select-role", "POST", data);
  };

  return (
    <Grid
      container
      columns={2}
      spacing={3}
      sx={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "20px",
      }}
    >
      {groups.map((group, index) => (
        <Grid item key={group.GroupName}>
          <Paper
            elevation={0}
            variant="outlined"
            sx={{
              width: editMode ? "200px" : "400px",
              borderRadius: "15px",
              overflow: "hidden",
              backgroundColor: "#262626",
              border: "2px solid rgba(218, 165, 32, 0.4)",
              opacity: 1,
            }}
          >
            {editMode ? (
              <EditCardHeader group={group} sides={sides} eventId={eventId} />
            ) : (
              <RoleCardHeader group={group} sides={sides} />
            )}
            {group.Roles.map((role) => (
              <List key={role.role_id} disablePadding>
                <ListItem
                  sx={{
                    paddingRight: "15px",
                    // "&:hover": {
                    //   backgroundColor: "#3a3a3a",
                    // },
                  }}
                >
                  {editMode ? (
                    <EditCardRoles role={role} eventId={eventId} />
                  ) : (
                    <RoleCardRoles
                      style={sides(group.Roles[0].side)}
                      role={role}
                      auth={auth}
                    />
                  )}
                  {editMode ? (
                    ""
                  ) : !role.assigned_to ? (
                    <Avatar
                      sx={
                        auth
                          ? {
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              "&:hover": {
                                backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                          : {
                              display: "none",
                              opacity: 0,
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              "&:hover": {
                                backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                      }
                      onClick={
                        auth
                          ? () => assignRole(role.RoleName, role.role_id)
                          : null
                      }
                    >
                      <AddCircleIcon />
                    </Avatar>
                  ) : currentUser &&
                    currentUser.username &&
                    role.assigned_to !== currentUser.username ? (
                    <Avatar
                      sx={
                        auth
                          ? {
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              opacity: 0.25,
                              "&:hover": {
                                //backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                          : {
                              display: "none",
                              opacity: 0,
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              "&:hover": {
                                backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                      }
                    >
                      <NotInterestedIcon
                        sx={{
                          color: "white",
                        }}
                      />
                    </Avatar>
                  ) : (
                    <Avatar
                      sx={
                        auth
                          ? {
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              "&:hover": {
                                backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                          : {
                              display: "none",
                              opacity: 0,
                              backgroundColor: sides(group.Roles[0].side)[0],
                              marginLeft: "15px",
                              "&:hover": {
                                backgroundColor: sides(group.Roles[0].side)[1],
                              },
                            }
                      }
                      onClick={() => assignRole(role.RoleName, role.role_id, 1)}
                    >
                      <NotInterestedIcon
                        sx={{
                          color: "white",
                        }}
                      />
                    </Avatar>
                  )}
                </ListItem>
              </List>
            ))}
            {editMode ? (
              <AddRole
                style={sides(group.Roles[0].side)}
                group={group}
                eventId={eventId}
              />
            ) : (
              ""
            )}
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};

export default RoleCard;

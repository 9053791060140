import React, { useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Layout from "./assets/Layout";
import ServerInfo from "./assets/membership/ServerInfo";
import Roster from "./assets/membership/Roster";

const HeadQuarters = () => {
  useEffect(() => {
    document.title = "Chrono-Company: HQ";
  }, []);

  const userInfo = useSelector((state) => state.auth.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userInfo || !userInfo.emailVerified) {
      navigate("/");
    }
  }, [userInfo, navigate]);

  return (
    <Layout>
      <Typography variant="h3" color="white">
        HEADQUARTERS
      </Typography>
      <Grid
        container
        spacing={2}
        sx={{
          margin: "20px auto",
          maxWidth: "1200px",
        }}
      >
        <Grid item xs={12} sm={8}>
          <ServerInfo />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Roster />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default HeadQuarters;

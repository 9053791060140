import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { auth, db } from "../funcs/firebase/firebase-config";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
  updateProfile,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { login } from "../../store/actions/authActions";

const RegisterForm = ({ onClose }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    username: "",
  });
  const [error, setError] = useState("");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, password, username } = formData;
    setError("");
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const { user } = userCredential;

      await updateProfile(user, {
        displayName: username,
      });

      await sendEmailVerification(user);

      await setDoc(doc(db, "users", user.uid), {
        username,
        active: true,
        rank: "Recruit",
        admin: 0,
        missionMaker: 0,
      });

      // Dispatch login action to update Redux state
      dispatch(login(email, password)).then(() => {
        // Close the modal after successful registration and login
        onClose();
      });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Register</h2>
        {error && <div className="error-message">Unable to register</div>}
        <form onSubmit={handleRegister}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="text"
              name="username"
              id="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button className="formButton" type="submit">
            Register
          </button>
          <button className="formButton" type="button" onClick={onClose}>
            Close
          </button>
        </form>
      </div>
    </div>
  );
};

export default RegisterForm;

import React, { useState } from "react";
import "../styles.css";
import LoginModal from "../login/LoginModal";
import RegisterModal from "../register/RegisterModal";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/actions/authActions";
import useCurrentUser from "../hooks/useCurrentUser";

//mui
import {
  AppBar,
  Typography,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import MenuIcon from "@mui/icons-material/Menu";

function Navbar() {
  const { currentUser, loading } = useCurrentUser();
  const user = useSelector((state) => state.auth.user);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLoginClick = (event) => {
    event.preventDefault();
    setShowLoginModal(true);
  };

  const handleRegisterClick = (event) => {
    event.preventDefault();
    setShowRegisterModal(true);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        CHRONO COMPANY
      </Typography>
      <List>
        <ListItem button component={Link} to="/missions">
          <ListItemText primary="MISSIONS" />
        </ListItem>
        {user && user.emailVerified && (
          <ListItem button component={Link} to="/hq">
            <ListItemText primary="HQ" />
          </ListItem>
        )}
        {user && user.emailVerified && currentUser && currentUser.admin && (
          <ListItem button component={Link} to="/ManageUsers">
            <ListItemText primary="MANAGE USERS" />
          </ListItem>
        )}
        {user ? (
          <>
            <ListItem button component={Link} to="/profile">
              <ListItemText
                primary={
                  (user.displayName && user.displayName.toUpperCase()) ||
                  (user.email && user.email.toUpperCase()) ||
                  ""
                }
              />
            </ListItem>
            <ListItem button onClick={handleLogout} component={Link} to="#">
              <ListItemText primary="LOGOUT" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button onClick={handleLoginClick} component={Link} to="#">
              <ListItemText primary="LOGIN" />
            </ListItem>
            <ListItem
              button
              onClick={handleRegisterClick}
              component={Link}
              to="#"
            >
              <ListItemText primary="REGISTER" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  if (loading) {
    return "";
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <FormGroup></FormGroup>
      <AppBar
        className="nav-bar-chrono"
        position="fixed"
        sx={{ bgcolor: "rgb(29, 29, 29)" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Link
            to="/"
            className="title"
            style={{ textDecoration: "none", flexGrow: 1 }}
          >
            <Typography
              variant="h4"
              noWrap
              sx={{ fontFamily: "Quantico", color: "white" }}
            >
              CHRONO COMPANY
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <div className="nav-menu">
              <Link to="/missions" className="link">
                MISSIONS
              </Link>
              {user && user.emailVerified ? (
                <Link to="/hq" className="link">
                  HQ
                </Link>
              ) : null}
              {user &&
              user.emailVerified &&
              currentUser &&
              currentUser.admin ? (
                <Link to="/ManageUsers" className="link">
                  MANAGE USERS
                </Link>
              ) : null}
              {user ? (
                <>
                  <Link to="/profile" className="link">
                    {(user.displayName && user.displayName.toUpperCase()) ||
                      (user.email && user.email.toUpperCase()) ||
                      ""}
                  </Link>
                  <Link to="#" onClick={handleLogout} className="link">
                    LOGOUT
                  </Link>
                </>
              ) : (
                <>
                  <Link to="#" onClick={handleLoginClick} className="link">
                    LOGIN
                  </Link>
                  <Link to="#" onClick={handleRegisterClick} className="link">
                    REGISTER
                  </Link>
                </>
              )}
            </div>
          </Box>
          {showLoginModal && (
            <LoginModal onClose={() => setShowLoginModal(false)} />
          )}
          {showRegisterModal && (
            <RegisterModal onClose={() => setShowRegisterModal(false)} />
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </Box>
  );
}

export default Navbar;

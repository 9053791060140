import { collection, getDocs } from "firebase/firestore";
import { db } from "./firebase/firebase-config";

const getUsers = async () => {
  const usersCol = collection(db, "users");
  const userSnapshot = await getDocs(usersCol);
  const userList = userSnapshot.docs.map((doc) => {
    const { username, active, rank } = doc.data();
    return { id: doc.id, username, active, rank };
  });
  return userList;
};

export default getUsers;

import React from "react";
import { useSelector } from "react-redux";
import RoleCard from "./RoleCard";

function RoleSelector({ event, currentUser, editMode }) {
  const userInfo = useSelector((state) => state.auth.user);

  return (
    <div
      style={{
        marginTop: "20px",
      }}
    >
      <RoleCard
        groups={event.Groups}
        eventId={event.event_id}
        currentUser={currentUser}
        auth={userInfo ? userInfo.emailVerified : false}
        editMode={editMode}
      />
    </div>
  );
}

export default RoleSelector;

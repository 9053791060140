import React, { useState } from "react";
import { Avatar, Input } from "@mui/material";
import { apiRequest } from "../../../../funcs/events";
import DeleteIcon from "@mui/icons-material/Delete";

const EditCardRoles = ({ role, eventId }) => {
  //console.log(role);
  const [editedRoleName, setRoleName] = useState(role.RoleName);
  const [changed, setChanged] = useState(false);

  const handleEditRoleName = (e) => {
    const newRoleName = e.target.value;
    setRoleName(newRoleName);
    setChanged(true);
  };

  const handleBlur = (e) => {
    if (changed) {
      const newRoleName = e.target.value;
      setChanged(false);
      const data = {
        event_id: eventId,
        roleId: role.role_id,
        newRoleName: newRoleName,
      };
      apiRequest("event/rolename", "POST", data);
    }
  };

  const handleDeleteRole = (roleId) => {
    const data = {
      event_id: eventId,
      roleId: role.role_id,
    };
    apiRequest("event/deleterole", "POST", data);
  };

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <Input
        onChange={handleEditRoleName}
        value={editedRoleName}
        onBlur={handleBlur}
        sx={{
          color: "white",
        }}
      />
      <Avatar
        onClick={() => handleDeleteRole(role)}
        sx={{
          margin: "5px",
          marginLeft: "20px",
          bgcolor: "maroon",
          "&:hover": {
            bgcolor: "red",
          },
        }}
      >
        <DeleteIcon />
      </Avatar>
    </div>
  );
};

export default EditCardRoles;

import React from "react";
import { Container } from "@mui/material";
import "../styles.css";
import styled from "styled-components";

const StyledContainer = styled.div`
  text-align: center;
  position: relative;
  width: 100%;
  height: 100vh;
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    width: '0px',
    background: 'transparent',
  }
  `;

const Layout = ({ children }) => {
  return (
    <StyledContainer>
      <Container maxWidth="xl" disableGutters>
        <div className="page-wrapper">{children}</div>
      </Container>
    </StyledContainer>
  );
};

export default Layout;

import React, { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { apiRequest } from "../../../../funcs/events";

const EditCardSide = ({ side, eventId, groupName }) => {
  const [selectedSide, setSelectedSide] = useState(side);

  const handleSideChange = (e, newSide) => {
    if (newSide !== null) {
      setSelectedSide(newSide);

      const getSideCode = (side) => {
        switch (side) {
          case "Blu-For":
            return "blu";
          case "Op-For":
            return "op";
          case "Independant":
            return "ind";
          default:
            return "";
        }
      };

      const data = {
        event_id: eventId,
        groupName: groupName,
        side: getSideCode(newSide),
      };
      apiRequest("event/side", "POST", data);
    }
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={selectedSide}
      exclusive
      onChange={handleSideChange}
      style={{ marginTop: "6px" }}
      sx={{
        "& .MuiToggleButtonGroup-grouped": {
          margin: "2px",
          borderColor: "grey",
          "&.Mui-selected": {
            borderColor: "inherit",
          },
          "&:not(:first-of-type)": {
            borderRadius: "4px",
          },
          "&:first-of-type": {
            borderRadius: "4px",
          },
        },
      }}
    >
      <ToggleButton
        value="Blu-For"
        selected={selectedSide === "Blu-For"}
        color="primary"
        disableRipple
      >
        Blu
      </ToggleButton>
      <ToggleButton
        value="Op-For"
        selected={selectedSide === "Op-For"}
        color="error"
        disableRipple
      >
        Op
      </ToggleButton>
      <ToggleButton
        value="Independant"
        selected={selectedSide === "Independant"}
        color="success"
        disableRipple
      >
        Ind
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default EditCardSide;

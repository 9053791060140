export const handleDownloadPreset = async (serverId) => {
  try {
    const response = await fetch(
      `https://ap2.chrono-company.com/api/v2/mods/${serverId}`
    );
    const data = await response.text();
    let presetName = "defaultPresetName";

    try {
      JSON.parse(data); // Try parsing as JSON
      throw new Error("Data is JSON parsable");
    } catch (error) {
      if (error instanceof SyntaxError) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html");
        const metaTag = doc.querySelector('meta[name="arma:PresetName"]');
        presetName = metaTag ? metaTag.content : "defaultPresetName";
      } else {
        throw error;
      }
    }

    const sanitizedPresetName =
      presetName.replace(/[^a-zA-Z0-9_-]/g, "_") + ".html";
    downloadFile(data, sanitizedPresetName);
  } catch (error) {
    showErrorPopup(
      "There was an error downloading the mod preset. The server likely has no mods loaded."
    );
  }
};

export const showErrorPopup = (message) => {
  const errorPopup = document.createElement("div");
  errorPopup.style.position = "fixed";
  errorPopup.style.bottom = "20px";
  errorPopup.style.right = "20px";
  errorPopup.style.backgroundColor = "red";
  errorPopup.style.color = "white";
  errorPopup.style.padding = "10px";
  errorPopup.style.borderRadius = "5px";
  errorPopup.style.zIndex = 1000;
  errorPopup.innerText = message;

  document.body.appendChild(errorPopup);

  setTimeout(() => {
    document.body.removeChild(errorPopup);
  }, 4000); // Remove pop-up after 4 seconds
};

export const downloadFile = (content, filename) => {
  const blob = new Blob([content], { type: "text/html" });
  const downloadLink = document.createElement("a");
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// Helper Functions
import * as memFuncs from "../../funcs/membership";
import FormButton from "../buttons/FormButton";
//import ModList from "./ModList";

const ServerInfo = () => {
  const userInfo = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [servers, setServers] = useState([]);
  const handleDownloadPreset = memFuncs.handleDownloadPreset;
  const getServerName = memFuncs.getServerName;

  useEffect(() => {
    if (!userInfo || !userInfo.emailVerified) {
      navigate("/");
    }
    const fetchServers = async () => {
      const response = await fetch(
        "https://ap2.chrono-company.com/api/v2/server-status"
      );
      const data = await response.json();
      setServers(data.original);
    };
    fetchServers();
  }, [userInfo, navigate]);

  return (
    <Paper
      elevation={0}
      sx={{
        backgroundColor: "rgba(0, 0, 0, 0)",
      }}
    >
      <Box>
        {servers.map((server, index) => (
          <Box
            key={index}
            sx={{
              padding: "10px",
              marginBottom: "20px",
              backgroundColor: "rgba(0,20,0,0.25)",
              boxShadow: "0 0 7px green",
              borderRadius: "5px",
            }}
          >
            <Typography
              variant="h5"
              sx={{ color: "white", fontFamily: "Quantico" }}
            >
              {getServerName(index)}
            </Typography>
            <Typography sx={{ color: "white", fontFamily: "Quantico" }}>
              {server.gq_online ? "Online" : "Offline"} - Active Players:{" "}
              {server.gq_numplayers || 0}
            </Typography>
            <div style={{ marginBottom: "10px" }} />
            <FormButton
              text={"Join Server"}
              href={
                getServerName(index) === "Liberation Server"
                  ? "steam://run/107410//+connect 77.68.27.159:2302 -noLauncher -skipIntro -noSplash"
                  : "steam://run/107410//+connect 77.68.27.159:2307 -noLauncher -skipIntro -noSplash"
              }
              disabled={!server.gq_online}
            />
            <div style={{ marginBottom: "10px" }} />
            <FormButton
              text={"Download Mod Preset"}
              onClick={() =>
                handleDownloadPreset(index === 0 ? "adffc976" : "d7e4b9c4")
              }
            />
            <div style={{ marginBottom: "5px" }} />
            {/* Mod List */}
            {/* <ModList mods={server.mods} /> */}
            {/* End Mod List */}
            {server.players.length > 0 && (
              <Box
                sx={{
                  marginTop: 2,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <List
                  dense
                  sx={{
                    border: "1px solid green",
                    borderRadius: "15px",
                    maxHeight: "300px",
                    overflowY: "auto",
                    bgcolor: 0,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      color: "white",
                      fontFamily: "Quantico",
                    }}
                  >
                    Player List:
                  </Typography>
                  {server.players.map((player, playerIndex) => (
                    <ListItem
                      key={playerIndex}
                      sx={{
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ListItemText
                        sx={{
                          textAlign: "center",
                          color: "white",
                          fontFamily: "Quantico",
                        }}
                        primary={player.name}
                        secondaryTypographyProps={{
                          color: "white",
                        }}
                        secondary={
                          "In-Game Time: " +
                          memFuncs.convertSeconds(player.time)
                        }
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
            )}
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default ServerInfo;

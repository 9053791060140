export const getServerName = (index) => {
  switch (index) {
    case 0:
      return "Ops Server";
    case 1:
      return "Liberation Server";
    default:
      return `Server ${index + 1}`;
  }
};

// Events.jsx
import React, { useState, useEffect } from "react";
import EventItem from "./assets/events/EventItem";
import { useSelector } from "react-redux";
import Pusher from "pusher-js";
import { Box, Typography, IconButton, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import * as eventFuncs from "./funcs/events";
import Layout from "./assets/Layout";
import "./styles.css";
import useCurrentUser from "./hooks/useCurrentUser";

const Events = () => {
  const fetchEventsData = async () => {
    try {
      const response = await fetch(
        "https://ap2.chrono-company.com/api/v2/events/list"
      );
      const data = await response.json();
      return data || [];
    } catch (error) {
      console.error("Failed to fetch events:", error);
      return [];
    }
  };

  const isAdmin = useSelector((state) => state.auth.isAdmin);
  const [events, setEvents] = useState([]);
  const { currentUser, loading } = useCurrentUser();

  useEffect(() => {
    const initializeEvents = async () => {
      const data = await fetchEventsData();
      setEvents(data);
    };

    initializeEvents();

    const pusher = new Pusher("c7769a0ff809f7ea2882", {
      cluster: "eu",
      encrypted: true,
    });

    const channel = pusher.subscribe("events-channel");
    channel.bind("events.updated", (data) => {
      setEvents(data.data);
    });

    return () => {
      channel.unbind_all();
      channel.unsubscribe();
      pusher.disconnect();
    };
  }, []);

  const createEvent = async () => {
    eventFuncs.apiRequest("events", "POST");
  };

  if (loading) {
    return <Layout></Layout>;
  }

  return (
    <Layout>
      <div style={{ marginTop: "" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesomeIcon
            icon={faCalendarDays}
            size="2xl"
            style={{ color: "#ffffff", fontSize: "50px", marginRight: "10px" }}
          />
          <Typography variant="h2" color="white" margin="100px 0 100px 0">
            UPCOMING EVENTS
          </Typography>
        </Box>
        {currentUser && currentUser.missionMaker && (
          <div style={{ marginTop: "-100px", marginBottom: "15px" }}>
            <IconButton onClick={createEvent}>
              <Tooltip title={<Typography>Create Event</Typography>}>
                <FontAwesomeIcon icon={faPlus} size="lg" color="white" />
              </Tooltip>
            </IconButton>
          </div>
        )}
      </div>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        {events
          .filter((event) => isAdmin || event.published)
          .map((event) => (
            <Grid item key={event.event_id}>
              <EventItem event={event} currentUser={currentUser} />
            </Grid>
          ))}
      </Grid>
    </Layout>
  );
};

export default Events;

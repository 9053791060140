const rankOrder = [
  "Commander",
  "Captain",
  "First Lieutenant",
  "Private",
  "Recruit",
  "Unranked",
];

export function sortUsersByRank(users) {
  return [...users].sort((a, b) => {
    const rankA = a.rank ? a.rank : "Unranked";
    const rankB = b.rank ? b.rank : "Unranked";
    return rankOrder.indexOf(rankA) - rankOrder.indexOf(rankB);
  });
}

import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Tooltip,
  Input,
} from "@mui/material";
import { apiRequest } from "../../../../funcs/events";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const AddRole = ({ group, eventId, style }) => {
  const [newRoleName, setNewRoleName] = useState();

  const handleNewRoleName = (e) => {
    const newRoleName = e.target.value;
    setNewRoleName(newRoleName);
  };

  const addNewGroup = (GroupName, eventId, newRoleName, side) => {
    if (!newRoleName) {
      return;
    }
    const data = {
      event_id: eventId,
      groupName: GroupName,
      newRoleName: newRoleName,
      side: side,
    };
    apiRequest("event/addrole", "POST", data);
    setNewRoleName("");
  };

  return (
    <Box
      sx={{
        backgroundColor: style[0],
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "90px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Input
          placeholder="New Role Name"
          value={newRoleName}
          onChange={handleNewRoleName}
        />
        <Avatar
          onClick={() =>
            addNewGroup(
              group.GroupName,
              eventId,
              newRoleName,
              group.Roles[0].side
            )
          }
          sx={{
            backgroundColor: style[0],
            "&:hover": {
              backgroundColor: style[1],
            },
          }}
        >
          <AddCircleIcon />
        </Avatar>
      </div>
    </Box>
  );
};

export default AddRole;

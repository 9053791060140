import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCMgcYB8lM3UZPgTklWYG_UJsdv4G1kwKY",
  authDomain: "chrono-company.firebaseapp.com",
  projectId: "chrono-company",
  storageBucket: "chrono-company.appspot.com",
  messagingSenderId: "1060459927805",
  appId: "1:1060459927805:web:ead7f5451c23f58db7c694",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

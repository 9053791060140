import React from "react";
import { Card } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import FormButton from "../buttons/FormButton";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomeCards = ({ title, text, link, buttonText, linkRef }) => {
  return (
    <>
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
        sx={{ minHeight: "200px", maxHeight: "250px" }}
      >
        <Card
          variant="outlined"
          sx={{
            minHeight: "200px",
            maxHeight: "200px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            color: "white",
            bgcolor: "#1d1d1d",
            boxShadow: "0 0 7px green",
            padding: "20px",
            marginBottom: "20px",
            backgroundColor: "rgba(0, 20, 0, 0.25)", // Removed redundancy in bgcolor and backgroundColor
          }}
        >
          <div>
            <h3>{title}</h3>
            <p>{text}</p>
          </div>
          {linkRef ? (
            <a href={linkRef} target="_blank" rel="noopener noreferrer">
              <FormButton text={buttonText} />
            </a>
          ) : (
            <Link to={link}>
              <FormButton text={buttonText} />
            </Link>
          )}
        </Card>
      </Grid>
    </>
  );
};

export default HomeCards;

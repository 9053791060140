import React, { useEffect } from "react";
import "./styles.css";
import Grid from "@mui/material/Unstable_Grid2";
import HomeCards from "./assets/homepage/HomeCards";
import Layout from "./assets/Layout";
import { Box, Paper } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HomePage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const items = [
    {
      content: (
        <>
          <img
            src="https://www.bohemia.net/assets/legacy/images/stories/arma3/screenshots/arma3_e32013_screenshot_07.jpg"
            alt="Placeholder"
          />
          <p>Placeholder</p>
        </>
      ),
    },
    {
      content: (
        <>
          <img
            src="https://www.dsogaming.com/wp-content/uploads/2013/03/arma3_screenshot_01.jpg"
            alt="Placeholder"
          />
          <p>Placeholder</p>
        </>
      ),
    },
    {
      content: (
        <>
          <img
            src="https://www.bohemia.net/assets/legacy/images/stories/arma3/screenshots/arma3_e32013_screenshot_01.jpg"
            alt="Placeholder"
          />
          <p>Placeholder</p>
        </>
      ),
    },
  ];
  useEffect(() => {
    document.title = "Chrono-Company: Home";
  }, []);

  const aboutUs =
    "We're a group of players that enjoy missions of all different eras, militaries, and franchises. The missions we play are handbuilt by some of the players and offer a unique experience every time. We also host a 24/7 Liberation server.";
  return (
    <Layout>
      <Grid container justifyContent="center" alignItems="center" spacing={3}>
        <HomeCards
          title="About Us!"
          text={aboutUs}
          linkRef="https://units.arma3.com/unit/chrono-company"
          buttonText="Arma Unit"
        />
        <HomeCards
          title="Our Missions!"
          text="This is where we keep track of our planned missions.  You can pick your roles in advanced using the role selector for each mission."
          link="./missions"
          buttonText="Missions"
        />
        <HomeCards
          title="Join Us!"
          text="Become a part of our community today!"
          linkRef="https://discord.com/invite/WQvnE3uVkH"
          buttonText="Enlist"
        />
      </Grid>
      <div style={{ display: "grid", placeItems: "center", width: "100vw%" }}>
        <Box sx={{ width: 1280, flexGrow: 1, marginTop: 12 }}>
          <Slider {...settings} lazyLoad>
            {items.map((item, index) => (
              <Paper
                key={index}
                elevation={3}
                sx={{
                  display: "flex",
                  flexDirection: "center", // This should be column or row
                  justifyContent: "center",
                  alignItems: "center",
                  bgcolor: "background.paper",
                }}
              >
                {item.content}
              </Paper>
            ))}
          </Slider>
        </Box>
      </div>
    </Layout>
  );
};

export default HomePage;

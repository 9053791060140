import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useFetchUsers from "./hooks/useFetchUsers";
import useHandleChange from "./hooks/useHandleChange";
import useCurrentUser from "./hooks/useCurrentUser";
import Layout from "./assets/Layout";

const UserManagement = () => {
  const theme = useTheme();
  const { users: fetchedUsers } = useFetchUsers();
  const navigate = useNavigate();
  const { currentUser, loading } = useCurrentUser();
  const { users, setUsers, handleChange } = useHandleChange([]);

  useEffect(() => {
    setUsers(fetchedUsers);
  }, [fetchedUsers, setUsers]);

  const rankOrder = [
    "Commander",
    "Captain",
    "First Lieutenant",
    "Corporal",
    "Private",
    "Recruit",
  ];

  React.useEffect(() => {
    if (!loading && (!currentUser || !currentUser.admin)) {
      navigate("/");
    }
  }, [loading, currentUser, navigate]);

  useEffect(() => {
    document.title = "Chrono-Company: Manage Users";
  }, []);

  return (
    <Layout>
      <Typography
        variant="h3"
        color="white"
        sx={{ marginBottom: "16px", textAlign: "center" }}
      >
        User Management
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          component="main"
          sx={{ width: "100%", maxWidth: "960px", padding: theme.spacing(3) }}
        >
          <Container maxWidth="lg">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper
                  elevation={0}
                  sx={{
                    padding: "20px",
                    height: "fit-content",
                    backgroundColor: "rgba(0,20,0,0.25)",
                    boxShadow: "0 0 7px green",
                    borderRadius: "5px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      maxHeight: "calc(100vh - 200px)",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    {rankOrder.map((rank) => (
                      <Box
                        key={rank}
                        sx={{ marginBottom: "16px", textAlign: "center" }}
                      >
                        <Typography variant="h6" color="white">
                          {rank}
                        </Typography>
                        {users
                          .filter((user) => user.rank === rank)
                          .map((user) => (
                            <Accordion
                              key={user.uid}
                              sx={{
                                backgroundColor: "transparent",
                                boxShadow: "none",
                                margin: "8px 0",
                                width: "100%",
                              }}
                            >
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel-${user.uid}-content`}
                                id={`panel-${user.uid}-header`}
                                sx={{
                                  justifyContent: "center",
                                  alignContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography color="white">
                                  {user.username}
                                </Typography>
                              </AccordionSummary>
                              <AccordionDetails
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="white"
                                    sx={{ marginRight: "16px" }}
                                  >
                                    Admin:
                                  </Typography>
                                  <input
                                    type="checkbox"
                                    checked={user.admin === true}
                                    onChange={(e) =>
                                      handleChange(
                                        user,
                                        "admin",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="white"
                                    sx={{ marginRight: "16px" }}
                                  >
                                    Mission Maker:
                                  </Typography>
                                  <input
                                    type="checkbox"
                                    checked={user.missionMaker === true}
                                    onChange={(e) =>
                                      handleChange(
                                        user,
                                        "missionMaker",
                                        e.target.checked
                                      )
                                    }
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "center",
                                    marginBottom: "8px",
                                  }}
                                >
                                  <Typography
                                    variant="body1"
                                    color="white"
                                    sx={{ marginRight: "16px" }}
                                  >
                                    Rank:
                                  </Typography>
                                  <select
                                    value={user.rank}
                                    onChange={(e) =>
                                      handleChange(user, "rank", e.target.value)
                                    }
                                  >
                                    <option value="Commander">Commander</option>
                                    <option value="Captain">Captain</option>
                                    <option value="First Lieutenant">
                                      First Lieutenant
                                    </option>
                                    <option value="Corporal">Corporal</option>
                                    <option value="Private">Private</option>
                                    <option value="Recruit">Recruit</option>
                                  </select>
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </Box>
                    ))}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </Layout>
  );
};

export default UserManagement;

const initialState = {
  isLoggedIn: false,
  user: null,
  error: null,
  isAdmin: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isLoggedIn: true, user: action.payload, error: null };
    case "LOGOUT_SUCCESS":
      return { ...state, isLoggedIn: false, user: null, isAdmin: false };
    case "AUTH_ERROR":
      return { ...state, error: action.payload };
    case "SET_ADMIN_STATUS":
      return { ...state, isAdmin: action.payload };
    default:
      return state;
  }
};

export default authReducer;

import React, { useState } from "react";
import { Box, Input } from "@mui/material";
import EditCardSide from "./EditCardSide";
import { apiRequest } from "../../../../funcs/events";

const EditCardHeader = ({ group, eventId, sides }) => {
  const [editedEventName, setEventName] = useState(group.GroupName);
  const [changed, setChanged] = useState(false);

  const handleEventNameChange = (e) => {
    const newEventName = e.target.value;
    setEventName(newEventName);
    setChanged(true);
  };

  const handleBlur = (e) => {
    if (!changed) {
      return;
    }
    setChanged(false);
    const newEventName = e.target.value;
    const data = {
      event_id: eventId,
      groupName: group.GroupName,
      newGroupName: newEventName,
    };
    apiRequest("event/groupname", "POST", data);
  };

  return (
    <Box
      sx={{
        backgroundColor: sides(group.Roles[0].side)[0],
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        height: "90px",
      }}
    >
      <div style={{ justifyContent: "center" }}>
        <Input
          value={editedEventName}
          sx={{ color: "white" }}
          onChange={handleEventNameChange}
          onBlur={handleBlur}
        />
        <br></br>
        <EditCardSide
          groupName={group.GroupName}
          eventId={eventId}
          side={sides(group.Roles[0].side, 1)}
        />
      </div>
    </Box>
  );
};

export default EditCardHeader;

// Modal.jsx
import React, { useEffect } from "react";
import ReactDOM from "react-dom";

const modalStyles = {
  position: "fixed",
  left: "50%",
  top: "53.4%",
  transform: "translate(-50%, -50%)",
  zIndex: 1005,
  color: "white",
  scrollbarWidth: "none",
  msOverflowStyle: "none",
  minWidth: "60vw",
};

const contentStyles = {
  overflow: "hidden",
  "&::-webkit-scrollbar": {
    width: "0px",
    background: "transparent",
  },
  transform: "scale(0.85)",
};

const overlayStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
};

const Modal = ({ children, isOpen, readme }) => {
  useEffect(() => {
    if (isOpen) {
      const preventScroll = (e) => e.preventDefault();
      document.body.style.overflow = "hidden";
      window.addEventListener("scroll", preventScroll, { passive: false });

      return () => {
        document.body.style.overflow = "auto";
        window.removeEventListener("scroll", preventScroll, { passive: false });
      };
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <>
      <div style={overlayStyles} />
      <div style={modalStyles}>
        <div style={contentStyles}>{children}</div>
      </div>
    </>,
    document.getElementById("modal-root")
  );
};

export default Modal;

import { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../funcs/firebase/firebase-config";

const useHandleChange = (initialUsers) => {
  const [users, setUsers] = useState(initialUsers);

  const handleChange = async (user, name, value) => {
    try {
      const userRef = doc(db, "users", user.uid);
      await updateDoc(userRef, { [name]: value });

      setUsers((prevUsers) =>
        prevUsers.map((u) => (u.uid === user.uid ? { ...u, [name]: value } : u))
      );
    } catch (error) {
      console.error(`Failed to update ${name}:`, error);
    }
  };

  return { users, setUsers, handleChange };
};

export default useHandleChange;
